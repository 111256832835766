import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import TeamContainer from '../containers/TeamContainer'

class TeamPage extends React.Component {
  render() {
    const [{ node: siteContents }] = get(this, 'props.data.allContentfulSiteContents.edges')
    const people = get(this, 'props.data.allContentfulPerson.edges').map(({ node }) => node)
    const jobDescriptions = get(this, 'props.data.allContentfulJd.edges').map(({ node }) => node)
    return (
			<TeamContainer
        language="ko"
        location={this.props.location}
        siteContents={siteContents}
        jobDescriptions={jobDescriptions}
				people={people}
			/>
    )
  }
}

export default TeamPage

export const pageQuery = graphql`
  query TeamQueryKo {
		allContentfulSiteContents(filter: {node_locale: {eq: "ko-KR"}}) {
			edges {
				node {
					description {
						description
					}
					companyName
					address1
					address2
					menu1
					menu2
					menu1Short
					menu2CtAurl
					menu2Cta
					menu2Short
					menu3
					menu3Categories
					menu3CtAurl
					menu3Cta
					menu4
					menu3Short
					menu4Cta
					menu4JdTitle
					menu4Short
					menu4Description {
						menu4Description
					}
					menu4Tags
					menu5
					menu5Short
          ogTitle
          ogDescription {
            ogDescription
          }
          ogImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
				}
			}
		}

    allContentfulPerson(filter: {node_locale: {eq: "ko-KR"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
					name
					image {
						file {
							details {
								image {
									height
									width
								}
							}
							url
            }
            sizes {
              srcSet
            }
					}
					shortBio {
						shortBio
					}
					title
        }
      }
    }
    allContentfulJd(sort: {fields: order, order: ASC}, filter: {node_locale: {eq: "ko-KR"}}) {
			edges {
				node {
					id
					position
					url
					description {
						description
					}
				}
			}
		}
  }
`
